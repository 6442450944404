export const READ_CHECKBOOK = 'READ_CHECKBOOK'
export const CREATE_CHECKBOOK = 'CREATE_CHECKBOOK'
export const UPDATE_CHECKBOOK = 'UPDATE_CHECKBOOK'

export const READ_CHEQUE = 'READ_CHEQUE'
export const CREATE_CHEQUE = 'CREATE_CHEQUE'
export const UPDATE_CHEQUE = 'UPDATE_CHEQUE'

export const READ_CLIENT = 'READ_CLIENT'
export const CREATE_CLIENT = 'CREATE_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const HISTORY_CLIENT = 'HISTORY_CLIENT'

export const READ_COMPANY = 'READ_COMPANY'
export const CREATE_COMPANY = 'CREATE_COMPANY'

export const READ_DASHBOARD = 'READ_DASHBOARD'

export const READ_EXPENSE = 'READ_EXPENSE'
export const CREATE_EXPENSE = 'CREATE_EXPENSE'
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE'
export const APPROVE_EXPENSE = 'APPROVE_EXPENSE'

export const READ_EXPENSE_TYPE = 'READ_EXPENSE_TYPE'
export const CREATE_EXPENSE_TYPE = 'CREATE_EXPENSE_TYPE'
export const UPDATE_EXPENSE_TYPE = 'UPDATE_EXPENSE_TYPE'


export const READ_FIELD = 'READ_FIELD'
export const CREATE_FIELD = 'CREATE_FIELD'
export const UPDATE_FIELD = 'UPDATE_FIELD'

export const READ_FORM = 'READ_FORM'
export const CREATE_FORM = 'CREATE_FORM'
export const UPDATE_FORM = 'UPDATE_FORM'

export const READ_INVOICE = 'READ_INVOICE'
export const CREATE_INVOICE = 'CREATE_INVOICE'
export const CANCEL_INVOICE = 'CANCEL_INVOICE'
export const VIEW_INVOICE_PDF = 'VIEW_INVOICE_PDF'
export const CHANGE_DATE_INVOICE = 'CHANGE_DATE_INVOICE'

export const READ_LOCATION = 'READ_LOCATION'
export const CREATE_LOCATION = 'CREATE_LOCATION'
export const UPDATE_LOCATION = 'UPDATE_LOCATION'

export const READ_PAYMENT_ENTRY = 'READ_PAYMENT_ENTRY'
export const CREATE_PAYMENT_ENTRY = 'CREATE_PAYMENT_ENTRY'
export const CANCEL_PAYMENT_ENTRY = 'CANCEL_PAYMENT_ENTRY'
export const UPDATE_PAYMENT_ENTRY_DEPOSIT = 'UPDATE_PAYMENT_ENTRY_DEPOSIT'
export const AUTH_PAYMENT_ENTRY = 'AUTH_PAYMENT_ENTRY'

export const READ_PAYMENT_TYPE = 'READ_PAYMENT_TYPE'
export const CREATE_PAYMENT_TYPE = 'CREATE_PAYMENT_TYPE'
export const UPDATE_PAYMENT_TYPE = 'UPDATE_PAYMENT_TYPE'
export const DEACTIVATE_PAYMENT_TYPE = 'DEACTIVATE_PAYMENT_TYPE'

export const READ_POSITION = 'READ_POSITION'
export const CREATE_POSITION = 'CREATE_POSITION'

export const READ_PRIORITY = 'READ_PRIORITY'
export const CREATE_PRIORITY = 'CREATE_PRIORITY'

export const READ_PRODUCT = 'READ_PRODUCT'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'

//Promociones
export const CREATE_PROMOTION = 'CREATE_PROMOTION'
export const UPDATE_PROMOTION = 'UPDATE_PROMOTION'


export const READ_REPORT = 'READ_REPORT'

export const READ_RESOLUTION = 'READ_RESOLUTION'
export const CREATE_RESOLUTION = 'CREATE_RESOLUTION'

export const READ_ROLE = 'READ_ROLE'
export const CREATE_ROLE = 'CREATE_ROLE'
export const UPDATE_ROLE = 'UPDATE_ROLE'

export const READ_SALE = 'READ_SALE'
export const CREATE_SALE = 'CREATE_SALE'
export const CANCEL_SALE = 'CANCEL_SALE'
export const DISCOUNT_SALE = 'DISCOUNT_SALE'

export const READ_SESSION = 'READ_SESSION'
export const CANCEL_SESSION = 'CANCEL_SESSION'

export const READ_STATUS = 'READ_STATUS'
export const CREATE_STATUS = 'CREATE_STATUS'
export const UPDATE_STATUS = 'UPDATE_STATUS'

export const READ_SUBSCRIPTION = 'READ_SUBSCRIPTION'
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION'
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'
export const EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION'
export const AUTOFEL_SUBSCRIPTION = 'AUTOFEL_SUBSCRIPTION'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const HISTORY_SUBSCRIPTION = 'HISTORY_SUBSCRIPTION'
export const GENERATE_NEXT_SUBSCRIPTION = 'GENERATE_NEXT_SUBSCRIPTION'

export const READ_TICKET = 'READ_TICKET'
export const CREATE_TICKET = 'CREATE_TICKET'
export const UPDATE_TICKET = 'UPDATE_TICKET'

export const READ_CONTA = 'READ_CONTA'
export const CREATE_CONTA = 'CREATE_CONTA'
export const UPDATE_CONTA = 'UPDATE_CONTA'


export const READ_TICKET_TYPE = 'READ_TICKET_TYPE'
export const CREATE_TICKET_TYPE = 'CREATE_TICKET_TYPE'
export const UPDATE_TICKET_TYPE = 'UPDATE_TICKET_TYPE'
export const UPDATE_TICKET_STATE = 'UPDATE_TICKET_STATE'

export const READ_USER = 'READ_USER'
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD'
export const UPDATE_USER_SESSION = 'UPDATE_USER_SESSION'

export const READ_WORKFLOW = 'READ_WORKFLOW'
export const CREATE_WORKFLOW = 'CREATE_WORKFLOW'
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW'

export const READ_WAREHOUSE = 'READ_WAREHOUSE'
export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE'
export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE'

export const READ_WAREHOUSE_ENTRY = 'READ_WAREHOUSE_ENTRY'
export const CREATE_WAREHOUSE_ENTRY_AJUSTMENT =
  'CREATE_WAREHOUSE_ENTRY_AJUSTMENT'
export const CREATE_WAREHOUSE_ENTRY_PURCHASE = 'CREATE_WAREHOUSE_ENTRY_PURCHASE'
export const CREATE_WAREHOUSE_ENTRY_TRASNFER = 'CREATE_WAREHOUSE_ENTRY_TRASNFER'
export const CREATE_WAREHOUSE_ENTRY_CONSIGNMENT =
  'CREATE_WAREHOUSE_ENTRY_CONSIGNMENT'
export const APPROVE_WAREHOUSE_ENTRY = 'APPROVE_WAREHOUSE_ENTRY'
export const CONFIRM_WAREHOUSE_ENTRY = 'CONFIRM_WAREHOUSE_ENTRY'

export const READ_MATERIAL_REQUEST = 'READ_MATERIAL_REQUEST'
export const CREATE_MATERIAL_REQUEST = 'CREATE_MATERIAL_REQUEST'
export const UPDATE_MATERIAL_REQUEST = 'UPDATE_MATERIAL_REQUEST'
export const CANCEL_MATERIAL_REQUEST = 'CANCEL_MATERIAL_REQUEST'

export const READ_PURCHASE_QUOTATION_REQUEST = 'READ_PURCHASE_QUOTATION_REQUEST'
export const CREATE_PURCHASE_QUOTATION_REQUEST =
  'CREATE_PURCHASE_QUOTATION_REQUEST'
export const UPDATE_PURCHASE_QUOTATION_REQUEST =
  'UPDATE_PURCHASE_QUOTATION_REQUEST'

export const READ_PURCHASE_QUOTATION = 'READ_PURCHASE_QUOTATION'
export const CREATE_PURCHASE_QUOTATION = 'CREATE_PURCHASE_QUOTATION'
export const UPDATE_PURCHASE_QUOTATION = 'UPDATE_PURCHASE_QUOTATION'
export const APPROVE_PURCHASE_QUOTATION = 'APPROVE_PURCHASE_QUOTATION'

export const READ_PURCHASE = 'READ_PURCHASE'
export const CREATE_PURCHASE = 'CREATE_PURCHASE'
export const UPDATE_PURCHASE = 'UPDATE_PURCHASE'

export const READ_SUPPLIER = 'READ_SUPPLIER'
export const CREATE_SUPPLIER = 'CREATE_SUPPLIER'
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER'

export const READ_MIKROTIK = 'READ_MIKROTIK'
export const CREATE_MIKROTIK = 'CREATE_MIKROTIK'
export const UPDATE_MIKROTIK = 'UPDATE_MIKROTIK'

export const VIEW_DASH_CORPORATIVO = 'VIEW_DASH_CORPORATIVO'

//Permisos para Corporativos
export const READ_CORP = 'READ_CORP'
export const CREATE_CORP = 'CREATE_CORP'
export const UPDATE_CORP = 'UPDATE_CORP'

//Modulo de Onus
export const READ_ONU = 'READ_ONU'
export const CREATE_ONU = 'CREATE_ONU'
export const UPDATE_ONU = 'UPDATE_ONU'


//Permisos para CATV
export const UPDATE_CATV = 'UPDATE_CATV'

//
export const READ_ACCOUNT = 'READ_ACCOUNT'
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'

//Tax
export const READ_TAX = 'READ_TAX'
export const CREATE_TAX = 'CREATE_TAX'
export const UPDATE_TAX = 'UPDATE_TAX'

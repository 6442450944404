import React from 'react'
import { Form, Header, Menu, Segment, MessageHeader, Message } from 'semantic-ui-react'

export default function Page(props) {
  return (
    <Segment basic style={{ marginTop: '0px' }}>
      {props.title && (
        <Menu text>
          <Menu.Item>
            <Header id='amber-page-title' as='h1' content={props.title} />
          </Menu.Item>

          <Menu.Menu position='right'>
            <Menu.Item id='amber-page-action' content={props.action} />
          </Menu.Menu>
        </Menu>
      )}

      {props.alert === 'error' && (<Message negative>
        <MessageHeader>{props.message}</MessageHeader>
        <p>{props.message_text}</p>
      </Message>)}

      {props.alert === 'sucess' && (<Message positive>
        <MessageHeader>{props.message}</MessageHeader>
        <p>{props.message_text}</p>
      </Message>)}
      {props.alert === 'info' && (<Message info
        header={props.message.title}
        list={props.message.list}
      />)}


      {props.form ? (
        props.raised ? (
          <Segment raised loading={props.loading}>
            <Form onSubmit={props.onSubmit}>{props.children}</Form>
          </Segment>
        ) : (
          <Form loading={props.loading} onSubmit={props.onSubmit}>
            {props.children}
          </Form>
        )
      ) : props.raised ? (
        <Segment raised>{props.children}</Segment>
      ) : (
        props.children
      )}
    </Segment>
  )
}

Page.defaultProps = {
  raised: false,
}

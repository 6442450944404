import AuthRoute from 'components/AuthRoute'
import {
  READ_CLIENT,
  READ_COMPANY,
  READ_EXPENSE,
  READ_EXPENSE_TYPE,
  READ_INVOICE,
  READ_LOCATION,
  READ_MATERIAL_REQUEST,
  READ_MIKROTIK,
  READ_PAYMENT_ENTRY,
  READ_PAYMENT_TYPE,
  READ_PRODUCT,
  READ_PURCHASE,
  READ_PURCHASE_QUOTATION,
  READ_PURCHASE_QUOTATION_REQUEST,
  READ_REPORT,
  READ_ROLE,
  READ_SALE,
  READ_SESSION,
  READ_SUBSCRIPTION,
  READ_SUPPLIER,
  READ_TICKET,
  READ_TICKET_TYPE,
  READ_USER,
  READ_WAREHOUSE,
  READ_WAREHOUSE_ENTRY,
  VIEW_DASH_CORPORATIVO,
  READ_ONU,
  READ_ACCOUNT
} from 'config/permissions'
import React, { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

const ClientRouter = lazy(() => import('core/client/ClientRouter'))
const ClientCorporateRouter = lazy(() => import('core/client_corporate/ClientCorporateRouter'))


const CompanyRouter = lazy(() => import('core/company/CompanyRouter'))
// const DashboardRouter = lazy(() => import('core/dashboard/DashboardRouter'))
const ExpenseRouter = lazy(() => import('core/expense/ExpenseRouter'))
const ExpenseTypeRouter = lazy(() =>
  import('core/expenseType/ExpenseTypeRouter')
)
const InvoiceRouter = lazy(() => import('core/invoice/InvoiceRouter'))
const LocationRouter = lazy(() => import('core/location/LocationRouter'))
const MaterialRequestRouter = lazy(() =>
  import('core/materialRequest/MaterialRequestRouter')
)
const MikrotikRouter = lazy(() => import('core/mikrotik/MikrotikRouter'))
const OnuRouter = lazy(() => import('core/onu/OnuRouter'))

const PaymentEntryRouter = lazy(() =>
  import('core/paymentEntry/PaymentEntryRouter')
)
const PaymentTypeRouter = lazy(() =>
  import('core/paymentType/PaymentTypeRouter')
)
const ProductRouter = lazy(() => import('core/product/ProductRouter'))
const PromotionRouter = lazy(() => import('core/promotion/PromotionRouter'))
const PurchaseRouter = lazy(() => import('core/purchase/PurchaseRouter'))
const PurchaseQuotationRouter = lazy(() =>
  import('core/purchaseQuotation/PurchaseQuotationRouter')
)
const PurchaseQuotationRequestRouter = lazy(() =>
  import('core/purchaseQuotationRequest/PurchaseQuotationRequestRouter')
)
const ReportRouter = lazy(() => import('core/report/ReportRouter'))
const RoleRouter = lazy(() => import('core/role/RoleRouter'))
const SaleRouter = lazy(() => import('core/sale/SaleRouter'))
const SessionRouter = lazy(() => import('core/session/SessionRouter'))
const SettingsRouter = lazy(() => import('core/settings/SettingsRouter'))
const SubscriptionRouter = lazy(() =>
  import('core/subscription/SubscriptionRouter')
)
const SupplierRouter = lazy(() => import('core/supplier/SupplierRouter'))
const TicketRouter = lazy(() => import('core/ticket/TicketRouter'))
const TicketTypeRouter = lazy(() => import('core/ticketType/TicketTypeRouter'))
const UserRouter = lazy(() => import('core/user/UserRouter'))
const WarehouseRouter = lazy(() => import('core/warehouse/WarehouseRouter'))
const WarehouseEntryRouter = lazy(() =>
  import('core/warehouseEntry/WarehouseEntryRouter')
)

const AccountRouter = lazy(() => import('core/account/AccountRuter'))
const TaxRouter = lazy(() => import('core/tax/TaxRouter'))
export default function AppRouter() {
  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <AuthRoute can={READ_CLIENT} path='/client' component={ClientRouter} />
        <AuthRoute can={VIEW_DASH_CORPORATIVO} path='/clientCorporate' component={ClientCorporateRouter} />

        <AuthRoute
          can={READ_COMPANY}
          path='/company'
          component={CompanyRouter}
        />

        {/* <AuthRoute
          can={READ_DASHBOARD}
          path='/dashboard'
          component={DashboardRouter}
        /> */}

        <AuthRoute
          can={READ_EXPENSE}
          path='/expense'
          component={ExpenseRouter}
        />

        <AuthRoute
          can={READ_EXPENSE_TYPE}
          path='/expense-type'
          component={ExpenseTypeRouter}
        />

        <AuthRoute
          can={READ_INVOICE}
          path='/invoice'
          component={InvoiceRouter}
        />

        <AuthRoute
          can={READ_LOCATION}
          path='/location'
          component={LocationRouter}
        />

        <AuthRoute
          can={READ_MATERIAL_REQUEST}
          path='/material-request'
          component={MaterialRequestRouter}
        />

        <AuthRoute
          can={READ_MIKROTIK}
          path='/mikrotik'
          component={MikrotikRouter}
        />
        <AuthRoute
          can={READ_ONU}
          path='/onus'
          component={OnuRouter}
        />

        <AuthRoute
          can={READ_PAYMENT_ENTRY}
          path='/payment-entry'
          component={PaymentEntryRouter}
        />

        <AuthRoute
          can={READ_PAYMENT_TYPE}
          path='/payment-type'
          component={PaymentTypeRouter}
        />

        <AuthRoute
          can={READ_PRODUCT}
          path='/product'
          component={ProductRouter}
        />

        <AuthRoute
          can={READ_PRODUCT}
          path='/promotion'
          component={PromotionRouter}
        />

        <AuthRoute
          can={READ_PURCHASE}
          path='/purchase'
          component={PurchaseRouter}
        />

        <AuthRoute
          can={READ_PURCHASE_QUOTATION}
          path='/purchase-quotation'
          component={PurchaseQuotationRouter}
        />

        <AuthRoute
          can={READ_PURCHASE_QUOTATION_REQUEST}
          path='/purchase-quotation-request'
          component={PurchaseQuotationRequestRouter}
        />

        <AuthRoute can={READ_REPORT} path='/report' component={ReportRouter} />

        <AuthRoute can={READ_ROLE} path='/role' component={RoleRouter} />

        <AuthRoute can={READ_SALE} path='/sale' component={SaleRouter} />

        <AuthRoute can={READ_ACCOUNT} path='/account' component={AccountRouter} />
        <AuthRoute can={READ_ACCOUNT} path='/tax' component={TaxRouter} />

        <AuthRoute
          can={READ_SESSION}
          path='/session'
          component={SessionRouter}
        />

        <AuthRoute
          can={READ_SUBSCRIPTION}
          path='/subscription'
          component={SubscriptionRouter}
        />

        <AuthRoute
          can={READ_SUPPLIER}
          path='/supplier'
          component={SupplierRouter}
        />

        <AuthRoute can={READ_TICKET} path='/ticket' component={TicketRouter} />

        <AuthRoute
          can={READ_TICKET_TYPE}
          path='/ticket-type'
          component={TicketTypeRouter}
        />

        <AuthRoute can={READ_USER} path='/user' component={UserRouter} />

        <AuthRoute
          can={READ_WAREHOUSE}
          path='/warehouse'
          component={WarehouseRouter}
        />

        <AuthRoute
          can={READ_WAREHOUSE_ENTRY}
          path='/warehouse-entry'
          component={WarehouseEntryRouter}
        />

        <Route component={SettingsRouter} />
      </Suspense>
    </React.Fragment>
  )
}

import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { fetchQuery } from 'config/amber-relay'
import Select from 'components/v2/Select'

export default function ClientSelect(props) {
  const fetch = async where => {
    const { clients } = await fetchQuery(
      graphql`
        query ClientSelectQuery($where: ClientWhere) {
          clients(where: $where, first: 10, orderBy: [name], order: ASC) {
            edges {
              node {
                id
                code
                name
                company {
        id
      }
                location {
                  name
                },
               
              }
            }
          }
        }
      `,
      { where }
    )

    const getText = node => {
      let text = node.name
      if (node.code) text = `${node.code} - ${text}`
      if (node.location && node.location.name)
        text = `${text} - ${node.location.name}`
      return text
    }

    return clients.edges.map(({ node }) => ({
      key: node.id,
      value: node.id,
      text: getText(node),
    }))
  }

  return (
    <Select
      {...props}
      getByValue={value => fetch({ id: { eq: value } })}
      loadOptions={searchQuery => {
        const conditions = [{ name: { iLike: `%${searchQuery}%` } }]

        if (!isNaN(parseInt(searchQuery)))
          conditions.push({ code: { eq: `${searchQuery}` } })

        return fetch({ or: conditions })
      }}
    />
  )
}

ClientSelect.defaultProps = {
  label: 'Cliente',
  placeholder: 'Cliente',
  loadOnMount: false,
}

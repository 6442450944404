/**
 * @flow
 * @relayHash b65c0e45cbc48a1417ef037032a19fda
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClientWhere = {|
  and?: ?$ReadOnlyArray<?ClientWhere>,
  or?: ?$ReadOnlyArray<?ClientWhere>,
  id?: ?ConditionInputType,
  code?: ?ConditionInputType,
  name?: ?ConditionInputType,
  phone?: ?ConditionInputType,
  cellPhone?: ?ConditionInputType,
  email?: ?ConditionInputType,
  address?: ?ConditionInputType,
  taxName?: ?ConditionInputType,
  taxUUID?: ?ConditionInputType,
  isCredit?: ?ConditionInputType,
  daysCredit?: ?ConditionInputType,
  taxAddress?: ?ConditionInputType,
  documentType?: ?ConditionInputType,
  uuid?: ?ConditionInputType,
  locationId?: ?ConditionInputType,
  companyId?: ?ConditionInputType,
  notes?: ?ConditionInputType,
  collectorId?: ?ConditionInputType,
  isDisabled?: ?ConditionInputType,
  updateInfoRequired?: ?ConditionInputType,
  createdAt?: ?ConditionInputType,
  createdBy?: ?ConditionInputType,
  updatedAt?: ?ConditionInputType,
  updatedBy?: ?ConditionInputType,
  saleBillingOutstandingAmount?: ?ConditionInputType,
  pendingSales?: ?ConditionInputType,
  invoicesCount?: ?ConditionInputType,
  subscriptionsCount?: ?ConditionInputType,
|};
export type ConditionInputType = {|
  eq?: ?string,
  ne?: ?string,
  gt?: ?string,
  gte?: ?string,
  lt?: ?string,
  lte?: ?string,
  is?: ?string,
  not?: ?string,
  in?: ?$ReadOnlyArray<?string>,
  notIn?: ?$ReadOnlyArray<?string>,
  like?: ?string,
  notLike?: ?string,
  iLike?: ?string,
  insub?: ?string,
|};
export type ClientSelectQueryVariables = {|
  where?: ?ClientWhere
|};
export type ClientSelectQueryResponse = {|
  +clients: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +code: number,
        +name: string,
        +company: ?{|
          +id: string
        |},
        +location: ?{|
          +name: string
        |},
      |}
    |}>
  |}
|};
export type ClientSelectQuery = {|
  variables: ClientSelectQueryVariables,
  response: ClientSelectQueryResponse,
|};
*/


/*
query ClientSelectQuery(
  $where: ClientWhere
) {
  clients(where: $where, first: 10, orderBy: [name], order: ASC) {
    edges {
      node {
        id
        code
        name
        company {
          id
        }
        location {
          name
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "ClientWhere",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": "ASC"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      "name"
    ]
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "code",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "company",
  "storageKey": null,
  "args": null,
  "concreteType": "Company",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ClientSelectQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "clients",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClientConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClientEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Client",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "location",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Location",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ClientSelectQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "clients",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ClientConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ClientEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Client",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "location",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Location",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ClientSelectQuery",
    "id": null,
    "text": "query ClientSelectQuery(\n  $where: ClientWhere\n) {\n  clients(where: $where, first: 10, orderBy: [name], order: ASC) {\n    edges {\n      node {\n        id\n        code\n        name\n        company {\n          id\n        }\n        location {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '446c3a09350fe929b5d98a902ca67180';
module.exports = node;
